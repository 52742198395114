// Based on https://medium.com/javascript-in-plain-english/google-analytics-with-react-router-and-hooks-16d403ddc528
// Modified to use firebase analytics

import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getAnalytics,setCurrentScreen,logEvent } from 'firebase/analytics'


export default function useTracking(): void {
  const { listen } = useHistory()

  useEffect(() => {
    return listen((location) => {
      const analytics = getAnalytics()
      setCurrentScreen(analytics,location.pathname)
      logEvent(analytics,'page_view', {
        page_location: window.location.href,
        page_path: location.pathname,
      })
    })
  }, [listen])
}
