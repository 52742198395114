import { Box, Checkbox, IconButton, ListItem, Stack, Typography, styled } from '@mui/material'
import DropdownModal from 'components/runs/DropdownModal'
import PrimaryButton from 'components/runs/PrimaryButton'
import DropdownArrowIcon from 'components/shared/icons/DropdownArrowIcon'
import { CellImageAction } from 'components/shared/CellImageControl'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { CellResponse } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import useCellIdsDownloader from 'components/shared/useCellIdsDownloader'
import useCellIdExport from './useCellIdExport'
import CellImageDownloadDialog from './CellImageDownloadDialog'

const DOWNLOAD_ZIP_CONFIG = {
  maxCellIds: 10,
  downloadLimit: 10,
  timeFrame: 10, // In-minutes
}

const StyledListItem = styled(ListItem)({
  color: '#000',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '1.2px',
  textTransform: 'uppercase',
  cursor: 'pointer',
  '&:hover': {
    background: '#E6E4FF',
  },
  '&:active': {
    color: '#5F55D1',
    background: '#E6E4FF',
  },
  '& > a': {
    textDecoration: 'none',
    color: '#000',
  },
})

interface CellBrowserExportProps {
  checkedCells: CellResponse[]
  handleQueryUpdate: (action: CellImageAction) => void
}

interface ProgressbarProps {
  completed: number | string
}

const ProgressContainer = styled('div')({
  height: '17px',
  width: '100%',
  padding: '1px',
  boxSizing: 'border-box',
  backgroundColor: '#EEEFFF',
  borderRadius: '10px',
  border: '1px solid #A4EFC0',
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
})

const ProgressBar = styled('div', {
  shouldForwardProp: (props) => props !== 'completed',
})<ProgressbarProps>(({ completed }) => {
  return {
    height: '100%',
    transition: 'width 3s ease-in-out',
    width: `${completed}%`,
    backgroundColor: '#5F55D1',
    borderRadius: completed === '100.0' ? '10px' : '10px 5px 5px 10px',
  }
})

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'center',
})

const CellBrowserExport = ({
  checkedCells,
  handleQueryUpdate,
}: CellBrowserExportProps): JSX.Element => {
  const [modalState, setModalState] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [checkboxState, setCheckboxState] = useState<boolean>(false)
  const { isInternalUser } = useAuthTokens()
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleExport = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setModalState(!modalState)
  }

  function CellReponseCSV(cells: CellResponse[]) {
    return cells.map(({ cell }) => {
      const cellId = cell.getCellId()
      const cellTime = cellId?.getTime()
      const instrumentId = cellId?.getInstrumentId()
      const cellNumber = cellId?.getNumber()

      return `${cellTime} #${cellNumber} ${instrumentId}`
    })
  }

  const { handleDownloadZip: handleTiff, loading: loadingTiff } = useCellIdsDownloader(
    'tiffDownload',
    {
      type: 'tiff',
      zippedFileName: '[TIFF]',
      cells: CellReponseCSV(checkedCells),
      ...DOWNLOAD_ZIP_CONFIG,
    }
  )

  const { handleDownloadZip: handlePng, loading: loadingPng } = useCellIdsDownloader(
    'pngDownload',
    {
      type: 'png',
      zippedFileName: '[PNG]',
      cells: CellReponseCSV(checkedCells),
      ...DOWNLOAD_ZIP_CONFIG,
    }
  )

  const { handleCSVExport, handleTSVExport, csvData, headers } = useCellIdExport()

  return (
    <>
      <Box data-testid="export-dropdown" sx={{ padding: '22px 30px 20px 24px' }}>
        <Stack sx={{ height: '30px' }} direction="row" justifyContent="space-between">
          <Stack direction="row" gap="8px" alignItems="center">
            <Checkbox
              sx={{ p: 0 }}
              checked={checkboxState}
              indeterminate
              onChange={(event) => {
                if (event.target.checked) {
                  handleQueryUpdate({ type: 'resetCheckboxSelection' })
                }
                setCheckboxState(event.target.checked)
              }}
            />
            <Typography
              sx={{
                color: '#5F55D1',
                fontSize: '12px',
                lineHeight: '13px',
                letterSpacing: '0.1em',
              }}
            >
              {checkedCells.length} SELECTED
            </Typography>
          </Stack>
          <PrimaryButton sx={{ width: '118px' }} onClick={handleExport}>
            <Stack direction="row" gap="8px" alignItems="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '13px',
                  letterSpacing: '0.1em',
                }}
              >
                Export
              </Typography>
              <IconButton sx={{ p: 0 }}>
                <DropdownArrowIcon sx={{ width: '18px', height: '18px', fill: '#5F55D1' }} />
              </IconButton>
            </Stack>
          </PrimaryButton>
        </Stack>
      </Box>
      <DropdownModal
        placement="bottom-start"
        disablePortal={false}
        transition
        anchorEl={anchorEl}
        open={modalState}
        onClickAway={() => setModalState(false)}
      >
        <Box sx={{ width: '118px' }}>
          {isInternalUser && (
            <StyledListItem
              onClick={() => {
                setShowModal(true)
                handlePng()
              }}
            >
              PNG
            </StyledListItem>
          )}
          <StyledListItem
            onClick={() => {
              setShowModal(true)
              handleTiff()
            }}
          >
            TIFF
          </StyledListItem>
          <StyledListItem>
            <CSVLink
              onClick={handleCSVExport}
              headers={headers}
              data={csvData}
              filename="[CSV] CellBrowsing.csv"
              target="_blank"
            >
              CSV
            </CSVLink>
          </StyledListItem>
          <StyledListItem onClick={handleTSVExport}>TSV</StyledListItem>
        </Box>
      </DropdownModal>

      <CellImageDownloadDialog
        id="download-modal"
        title="Exporting"
        keepMounted
        open={showModal && (loadingPng || loadingTiff)}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <Stack gap="10px">
          <StyledTypography>
            Exporting the selected cell images. It may take some time.
          </StyledTypography>
          <ProgressContainer>
            <ProgressBar completed="40" />
          </ProgressContainer>
        </Stack>
      </CellImageDownloadDialog>
    </>
  )
}

export default CellBrowserExport
