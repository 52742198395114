import { Alert, Box, LinearProgress, Snackbar, SnackbarCloseReason, styled } from '@mui/material'
import Parser from 'html-react-parser'
import { NotificationState } from 'redux/slices'

const NotificationAlert = styled(Alert)({
  width: '100%',
  '& a': { color: 'white', fontWeight: 'bold' },
})

const ProgressClass = styled(LinearProgress)({
  position: 'relative',
  top: '-10px',
  margin: '0 20px',
})

const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-anchorOriginBottomCenter': {
    bottom: '100px',
  },
})

export interface NotificationBaseProps {
  notification: NotificationState
  handleClose(_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason): void
}

export const ToasterComponent = (props: NotificationBaseProps): JSX.Element => {
  const {
    handleClose,
    notification: { message, open, origin, showIcon, showProgress, timeout, type, onClick },
  } = props

  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    if (onClick) {
      onClick()
      handleClose()
    }
  }
  const handleCloseClick = (event: React.SyntheticEvent | Event) => {
    event.stopPropagation();
    handleClose(event);
  };

  const renderMessage = (toastMessage: string | React.ReactNode): JSX.Element | null => {
    // If toastMessage is undefined or null, return null or a default message
    if (toastMessage === undefined || toastMessage === null) {
      return null;
    }
  
    if (typeof toastMessage === 'string') {
      // If it's a string, parse it as HTML
      return <>{Parser(toastMessage)}</>;
    }
    
    // If it's a JSX element, render it directly
    return <>{toastMessage}</>;
  };

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={timeout}
      onClose={handleClose}
      anchorOrigin={{
        vertical: origin?.vertical ?? 'top',
        horizontal: origin?.horizontal ?? 'center',
      }}
      onClick={handleClick}
    >
      <Box>
        <NotificationAlert
          variant="filled"
          onClose={handleCloseClick}
          severity={type}
          icon={showIcon ? undefined : false}
          sx={{ width: '100%' }}
          onClick={handleClick}
          
        >
          {renderMessage(message)}
        </NotificationAlert>
        {showProgress && <ProgressClass />}
      </Box>
    </StyledSnackbar>
  )
}

export default ToasterComponent
