import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNotificationSlice } from 'redux/slices/hooks'
import { getEmbeddingsId, getEmbeddingsURL } from './api'

interface UseEmbeddingsDownloadInterface {
  runIdStrings: string | undefined // runIds from selected Runs needed for generating taskId
  setOpenSaveDialogue: (value: boolean) => void // setter function to open save dialogue to give custom name to file
}

interface EmbeddingDownloadData {
  fileURL: string
  downloadEmbeddings: () => void
}

const useDownloadEmbeddings = ({
  runIdStrings,
  setOpenSaveDialogue,
}: UseEmbeddingsDownloadInterface): EmbeddingDownloadData => {
  const { displayNotification, showProgress } = useNotificationSlice()
  const [embeddingsId, setEmbeddingsId] = useState(0)
  const [isPolling, setIsPolling] = useState(true)
  const [hasShownPendingNotification, setHasShownPendingNotification] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [retryCount, setRetryCount] = useState(0)
  const POLL_WAIT = 2000
  const MAX_POLL_COUNT = 10

  const downloadEmbeddings = async () => {
    if (runIdStrings) {
      showProgress('Preparing your file...')
      const result = await getEmbeddingsId({ run_ids: [runIdStrings] })
      setEmbeddingsId(result?.data?.id)
    }
  }

  useQuery(['getEmbeddingsURL', embeddingsId], () => getEmbeddingsURL(embeddingsId), {
    enabled: !!embeddingsId,
    retry: false,
    refetchInterval: isPolling && retryCount < MAX_POLL_COUNT ? POLL_WAIT : false,
    onError: async (error: Error) => {
      setEmbeddingsId(0)
      setHasShownPendingNotification(false)
      displayNotification({
        message: (
          <>
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              Please try again.{' '}
            </span>
            If the error reoccurs, please contact our support to assist with this request:
            support@deepcell.com.
          </>
        ),
        type: 'error',
        showIcon: false,
        origin: { vertical: 'top', horizontal: 'center' },
        component: 'ToasterComponent',
        onClick: () => {
          downloadEmbeddings()
        },
        timeout: 3000,
      })
      console.error(error, 'error')
    },
    onSuccess: async (response) => {
      if (response.data.status === 'pending' && !hasShownPendingNotification) {
        setHasShownPendingNotification(true)
        displayNotification({
          message: 'Your download request is in progress. We’ll notify you once it’s ready.',
          type: 'info',
          showIcon: false,
          origin: { vertical: 'top', horizontal: 'center' },
          timeout: 1500,
          component: 'ToasterComponent',
        })
        setRetryCount((prev) => prev + 1)
      }
      if (response.data.status === 'ready') {
        setIsPolling(false)
        setRetryCount(0)
        setFileURL(response.data.download_url)
        setHasShownPendingNotification(false)
        displayNotification({
          message: (
            <>
              Your download request is ready.{' '}
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Click here to download your embeddings.
              </span>
            </>
          ),
          type: 'info',
          showIcon: false,
          origin: { vertical: 'top', horizontal: 'center' },
          component: 'ToasterComponent',
          onClick: () => setOpenSaveDialogue(true),
          timeout: 3000,
        })
        setEmbeddingsId(0)
      }
    },
  })
  return { fileURL, downloadEmbeddings }
}

export default useDownloadEmbeddings
