import { Typography, styled } from '@mui/material'
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  IDatasource,
  IGetRowsParams,
  ModelUpdatedEvent,
  RowClassParams,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { memo, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useNotificationSlice } from 'redux/slices/hooks'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { CellResponse, CellsQueryParams, getCells } from 'utils/api'
import { messagesEqual } from 'utils/proto-utils'
import { QueryState, getFindCellsQueryParams } from '../cellSearchHelpers'
import { CACHE_TIME, LIMIT } from '../constants'
import { getCellTableColumnDefs, getCellTableRowData } from './metadata'

const TableContainer = styled('div')({
  width: '100%',
  height: 'calc(100vh - 150px)',
})

const headerHeight = 40

const rowStyle = {
  boxSizing: 'border-box',
  padding: '0 15px',
  borderRadius: '4px',
  background: '#FFF',
  boxShadow:
    '0px 1px 12px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.01)',
}

interface Props {
  selectedCells: CellResponse[]
  searchOptions: QueryState
  setTableGridApi: React.Dispatch<React.SetStateAction<GridApi | null>>
}

const CellBrowserTable = ({
  selectedCells,
  setTableGridApi,
  searchOptions,
}: Props): JSX.Element => {
  const {
    setSelectedCells,
    appendRowData,
    cellBrowsing: { rowData },
  } = useCellBrowsingSlice()

  const { displayNotification } = useNotificationSlice()

  const queryClient = useQueryClient()

  const serverDataSource: IDatasource = {
    getRows: async (getRowParams: IGetRowsParams) => {
      const { startRow, successCallback, failCallback } = getRowParams

      if (startRow === 0 && rowData.length) {
        successCallback(getCellTableRowData(rowData))
      } else {
        const pageOffset = startRow / 50 + 1
        const params: CellsQueryParams = getFindCellsQueryParams(
          { ...searchOptions, page: pageOffset },
          false
        )

        try {
          const results = await queryClient.fetchQuery(['getCells', params], getCells, {
            staleTime: CACHE_TIME,
          })

          let lastRow = -1
          if (results.cells.length < LIMIT) {
            displayNotification({ message: "You've reached the end", type: 'success' })
            lastRow = startRow + results.cells.length
          }

          successCallback(getCellTableRowData(results.cells), lastRow)
          appendRowData(results.cells)
        } catch (err) {
          console.error(err)
          failCallback()
        }
      }
    },
  }

  const onModelUpdated = (params: ModelUpdatedEvent) => {
    const renderedRowCount = params.api.getModel().getRowCount()
    if (selectedCells.length && renderedRowCount > 1) {
      params.api.forEachNode((node) => {
        const isSelected =
          selectedCells.findIndex((cell) => {
            const cellId = cell.cell.getCellId()
            return messagesEqual(cellId, node.data?.cell_proto.cell.getCellId())
          }) > -1
        node.setSelected(isSelected)
      })
    }
  }

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit() 
    if (params.api) {
      setTableGridApi(params.api) 
    }

    // Connect datasource
    params.api.setDatasource(serverDataSource)
  }

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const rowsNode = event.api.getSelectedNodes()
      const rowSelected = rowsNode.map((rowNode) => rowNode.data.cell_proto)  
      setSelectedCells(rowSelected)
    },
    [setSelectedCells]
  )

  return (
    <>
      {rowData.length === 0 ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>No cells matched your query</Typography>
      ) : (
        <TableContainer
          className={`ag-theme-v3-browsing-table context-menu-ag-grid ${
            selectedCells.length ? 'no-root-padding' : ''
          }`}
          data-testid="cell-browsing-runs-table"
        >
          <AgGridReact
            onGridSizeChanged={(params: GridSizeChangedEvent) => params.api.sizeColumnsToFit()}
            rowStyle={rowStyle}
            rowSelection="multiple"
            headerHeight={headerHeight}
            onGridReady={onGridReady}
            rowModelType="infinite"
            columnDefs={getCellTableColumnDefs}
            cacheBlockSize={50}
            blockLoadDebounceMillis={2000}
            suppressDragLeaveHidesColumns
            onSelectionChanged={onSelectionChanged}
            onModelUpdated={onModelUpdated}
            getRowStyle={(params: RowClassParams) => {
              const { rowIndex } = params.node
              return {
                marginTop:
                  rowIndex === null || rowIndex === undefined ? '0' : `${10 * (rowIndex + 1)}px`,
                marginLeft: '10px',
              }
            }}
            rowBuffer={50}
          />
        </TableContainer>
      )}
    </>
  )
}

export default memo(CellBrowserTable)
