import { ColDef } from 'ag-grid-community'
import { SessionMetaData } from 'utils/api'
import moment from 'moment';
import { formatTimestampToHumanReadable, INSTRUMENT_REPORTED_TIMEFORMAT } from 'components/shared/date-utils';
import OpenSessionComponent from './OpenSessionComponent';

interface CellVisualizationTableColumnProps {
  handleOpenSession?: (value: number) => void
}

export const getCellVisualizationTableColumnDefs = ({handleOpenSession}: CellVisualizationTableColumnProps): ColDef[] => {
  return [
    {
      headerName: 'Session Name',
      field: 'name',
      sortable:true,
      maxWidth:200,
      cellStyle:{color: '#5F55D1',borderLeft: '1px solid #EDEDED'},

    },
    {
      headerName: 'Created By',
      field: 'author_email',
      flex: 1,
      sortable:true,
    },
    {
      headerName: 'Last Updated',
      field: 'updated_at',
      valueFormatter: (params) => params.value ?  formatTimestampToHumanReadable(moment.utc(params.value).format(), INSTRUMENT_REPORTED_TIMEFORMAT) : '',
      flex: 1,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      valueFormatter: (params) => params.value ?  formatTimestampToHumanReadable(moment.utc(params.value).format(), INSTRUMENT_REPORTED_TIMEFORMAT) : '',
      flex: 1,
      sortable:true,
    },
    {
      headerName: 'Project Code',
      field: 'project_code',
      flex: 1,
    },
    
    {
      headerName: 'Run Ids',
      field: 'run_id',
      flex: 1,
      cellStyle: { color: '#5F55D1',fontSize:'14px' },
      wrapText:true,
      autoHeight:true,

    },
    {
      headerName: '',
      field: 'open_session',
      cellRendererFramework: OpenSessionComponent,
      cellRendererParams: {
        handleOpenSession
      },
    },
   ]
}

export type CellVisualizationRowData = {
 sessionId: number
  name: string
  project_code: string
  created_at: Date | string
  updated_at: Date | string
  author_email: string
  run_id:string
 }


  export const getCellVisualizationData = (visualizationSessions: SessionMetaData[]): CellVisualizationRowData[] => {
    return visualizationSessions.map((visSession) => {
      const sessionId = visSession.session_id
      const runIds = visSession?.runs?.map((run) => run?.run_id);
      const displayRunIds = runIds?.length > 2
        ? `${runIds?.slice(0, 1).join(', ')} + ${runIds?.length - 1} more`
        : runIds?.join(', ');
         
      return {
        sessionId,
        name: visSession.version_config.name ? visSession.version_config.name: visSession.created_at + visSession.author_email,
        project_code: visSession.version_config.projectCode
          ? visSession.version_config.projectCode
          : '-',
        author_email: visSession.author_email || '',
        created_at: visSession.created_at ? visSession.created_at : '',
        updated_at: visSession.updated_at ? visSession.updated_at : '',
        run_id: displayRunIds 
      }
    })
  }
