import { Stack, Typography, styled } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import { ReactElement } from 'react'

interface ProgressbarProps {
  completed: number | string
}

const ProgressContainer = styled('div')({
  height: '9px',
  width: '69px',
  padding: '1px',
  boxSizing: 'border-box',
  backgroundColor: '#EEEFFF',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
})

const ProgressBar = styled('div', {
  shouldForwardProp: (props) => props !== 'completed',
})<ProgressbarProps>(({ completed }) => {
  return {
    height: '100%',
    transition: 'width 3s ease-in-out',
    width: `${completed}%`,
    backgroundColor: '#5F55D1',
    borderRadius: completed === '100.0' ? '10px' : '10px 5px 5px 10px',
  }
})

const ProgressCellComponent = (props: Partial<ICellRendererParams>): ReactElement => {
  const {
    data,
    colDef: { field, cellRendererParams: { showCount = false } = {} } = {},
    valueFormatted,
  } = props

  const count = field ? data[field] : 0
  const progress = parseFloat(valueFormatted ?? '0').toFixed(1)

  return (
    <Stack gap="15px" direction="row" alignItems="center">
      <ProgressContainer data-testid="progress-container">
        <ProgressBar data-testid="progress-bar" completed={progress} />
      </ProgressContainer>
      <Typography sx={{ fontSize: '12px' }}>{showCount ? count : `${progress}%`}</Typography>
    </Stack>
  )
}

export default ProgressCellComponent
