import { AlertColor, SnackbarOrigin } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react'

export const notificationComponent = {
  Toaster: 'ToasterComponent',
  Dialog: 'DialogComponent',
} as const

export type NotificationComponent =
  (typeof notificationComponent)[keyof typeof notificationComponent]

export interface NotificationState {
  open?: boolean
  type?: AlertColor
  message?: string | ReactNode
  timeout?: number | null
  closeOnClickaway?: boolean
  showProgress?: boolean
  showIcon?: boolean
  origin?: SnackbarOrigin
  component?: NotificationComponent
  onClick?: () => void
}

export const notificationInitialState: NotificationState = {
  open: false,
  type: 'info',
  message: '',
  timeout: null,
  closeOnClickaway: false,
  showProgress: false,
  showIcon: true,
  component: notificationComponent.Toaster,
  onClick:undefined
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    displayNotification: (_state, action: PayloadAction<NotificationState>) => ({
      ...notificationInitialState,
      ...action.payload,
      open: true,
    }),
    clearNotification: (state) => ({ ...state, open: false }),
  },
})

export const NotificationActions = NotificationSlice.actions
export const NotificationReducer = NotificationSlice.reducer
