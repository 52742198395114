
import useCellVisualizationUrlParams from 'components/cell-visualizations/useCellVisualizationUrlParams'
import { useEffect, useRef } from 'react'
import { CellVisualizationsState } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { datasetItems } from 'redux/types'
import { useAppIsReady } from 'utils/useAppIsReady'
import { getSession } from 'utils/api'
import { CELL_IMAGE_NORMAL_SIZE, CELL_IMAGE_NORMAL_SPACING } from '../shared'
import { getCellInfoToGroups } from '../utils'
import getSessionData from './getSessionData'

export const useLoadCellInfoEffect = (onError: (error: unknown) => void): void => {
  const { sessionId, versionId, updateVersion, isPreFilter } = useCellVisualizationUrlParams();
  const { setStore, cellVisualizations: { pinnedCells }, setSessionData } = useCellVisualizationsSlice();

  const loaded = useRef(false);
  const isGettingSession = useRef(false);
  const appIsReady = useAppIsReady();
  const readyToGetData = sessionId && appIsReady && !loaded.current && !isGettingSession.current;
 
  
  useEffect(() => {
    if (!readyToGetData) return

    // TODO: Address this ticket: https://deepcellbio.atlassian.net/browse/SWT-381
    // This should go away when we address SWT-381
    const getArrowTable = async () => {
      const {
        status: sessionStatus,
        data: { data: sessionData },
      } = await getSession(sessionId, versionId) 

      const { cellsData }= await getSessionData({sessionId,sessionData,sessionStatus})
      isGettingSession.current = true;
      try {

        if (cellsData && sessionData) {

          loaded.current = true;
          const { version_config: state } = sessionData;
          if (state) {

            const matchingDsi = datasetItems.find((dsi) => dsi.id === `${sessionId}`);
            const extraData: Partial<CellVisualizationsState> | undefined = isPreFilter
              ? {
                  selectedCellInfoGroupName: 'leiden',
                  cellImagesFilter: {
                    displayImages: true,
                    imageSize: CELL_IMAGE_NORMAL_SIZE / 1.5,
                    spacingAdjust: CELL_IMAGE_NORMAL_SPACING / 1.5,
                  },
                }
              : undefined
            const removedCellIds = new Set<string>(
              state.morphotypesToRemove?.flatMap(
                (m) => m.cells.points?.flatMap((p) => p.cellId ?? []) ?? []
              )
            )
            const unRemovedCellData = cellsData.filter((cd) => !removedCellIds.has(cd.CELL_ID))

            if (!pinnedCells?.length) {
              setStore({
                ...state,
                ...extraData,
                cellsData,
                cellInfoGroups: getCellInfoToGroups(unRemovedCellData),
                selectedDataset: matchingDsi ?? state.selectedDataset,
              })
            }
          }

          if (sessionData.version_id) {
            updateVersion(sessionData.version_id, isPreFilter)
          }
        }
        setSessionData(sessionData)
      } catch (error) {
        onError(error)
      } finally {
        isGettingSession.current = false
      }
    }

    getArrowTable()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPreFilter,
    pinnedCells?.length,
    readyToGetData,
    sessionId,
    setStore,
    updateVersion,
    versionId,
    onError,
  ])

  useEffect(() => {
    isGettingSession.current = false
    loaded.current = false
  }, [sessionId])
}

export default useLoadCellInfoEffect
