import Typography from '@mui/material/Typography'
import { Box, styled } from '@mui/system'
import { getAnalytics,logEvent } from 'firebase/analytics'
import { useEffect, useMemo, useState } from 'react'
import { SAMPLE_DISTRIBUTION } from 'utils/constants'
import SplitterLayout from 'react-splitter-layout'
import SectionCard from 'components/shared/SectionCard'
import { Tooltip } from '@mui/material'
import { GridApi, GridReadyEvent } from 'ag-grid-community'
import DownloadToggleIcon from 'components/shared/icons/DownloadToggleIcon'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { useHotkeys } from 'react-hotkeys-hook'
import CellSearchFilters from './CellSearchFilters'
import { prefetchPages } from './cellSearchHelpers'
import CellSearchOptions from './CellSearchOptions'
import useCellBrowsingQueryParams from './useCellBrowsingQueryParams'
import SearchSidebar from './SearchSidebar'
import CellDetailsPanel from './CellDetailsPanel'
import CellBrowserExport from './CellBrowserExport'
import CellBrowsingGridView from './gridView/CellBrowsingGridView'
import CellBrowsingTableView from './tableView/CellBrowsingTableView'
import { CellBrowsingAction, SearchFilters, ViewModeType } from './types'

const PAGES_TO_PREFETCH = 2
const PREFETCH_DELAY = 500

const IconSx = {
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  '&:hover > rect': { fill: '#a1eabc' },
}

type ViewContainerProps = {
  exportPanel: boolean
}

const ViewContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'exportPanel',
})<ViewContainerProps>(({ exportPanel }) => ({
  overflow: 'auto',
  height: exportPanel ? 'calc(100vh - 176px)' : 'calc(100vh - 104px)',
  paddingTop: exportPanel ? '0px !important' : 'inherit',
}))

function CellBrowsingPage(): JSX.Element {
  const analytics = getAnalytics()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false)
  const [cellDetailsGridApi, setCellDetailsGridApi] = useState<GridApi | null>(null)
  const [tableGridApi, setTableGridApi] = useState<GridApi | null>(null)
  const [viewMode, setViewMode] = useState<ViewModeType>({
    grid: true,
    table: false,
  })

  const { query, setQuery, search, displayOptions, queryClient, searchingAcrossCorpus } =
    useCellBrowsingQueryParams()

  const {
    appendNewCells,
    clearSelectedCellIds,
    clearRowData,
    setTotalRowData,
    cellBrowsing: { selectedCells },
  } = useCellBrowsingSlice()

  // @TODO Remove old runId conversion when it's no longer used
  if (query.runId) {
    const { runId } = query
    if (query.runIds.length === 0) {
      setQuery({ ...search, runIds: [runId], runId: undefined })
      query.runIds = [runId]
    } else {
      setQuery({ ...search, runId: undefined })
    }
    query.runId = undefined
  }

  /**
   * Handles state updates by updating the state of the URL query
   */
  function handleQueryUpdate(action: CellBrowsingAction) {
    switch (action.type) {
      case 'sortOrderKey':
        if (action.value === SAMPLE_DISTRIBUTION) {
          setQuery({
            ...search,
            sortOrderKey: action.value,
            selectedCellId: undefined,
          })
        } else {
          // when sort order changes, keep the existing counts but jump to first result page
          setQuery({ ...search, sortOrderKey: action.value, page: 0 })
        }
        clearRowData()
        clearSelectedCellIds()
        setTotalRowData(0)
        setViewMode({ grid: true, table: false })
        break

      case 'reset':
        setQuery({
          ...search,
          predictedClasses: undefined,
          predictedProbabilityGreaterThan: undefined,
          sampleType: undefined,
          sampleId: undefined,
          mixedSampleId: undefined,
          cellId: undefined,
          before: undefined,
          after: undefined,
          runIds: undefined,
          checkedCellIds: undefined,
        })
        break

      case 'cellId':
        if (search?.cellId) {
          setQuery({
            ...search,
          })
        }
        break

      case 'search':
        // Update search and reset the count
        setQuery({ ...search, ...action.searchFilters, page: 0 })
        break

      case 'resetCheckboxSelection':
        clearSelectedCellIds()
        break

      case 'clickCell':
        appendNewCells(action.cell)
        setDetailsPanelOpen(true)
        break

      case 'toggleCenterCrop':
        setQuery({
          ...search,
          centerCrop: action.value,
        })
        logEvent(analytics,'CellBrowsingPage/toggleCenterCrop', search)
        break

      case 'toggleSharpen':
        setQuery({
          ...search,
          sharpen: !search.sharpen,
        })
        logEvent(analytics,'CellBrowsingPage/toggleSharpen', search)
        break

      case 'quadrant':
        setQuery({
          ...search,
          quadrant: action.value,
        })
        // analytics.logEvent('CellBrowsingPage/updateQuadrant', search)
        break

      default:
        throw Error('Unhandled action')
    }
  }
  useEffect(() => {
    if (!selectedCells.length) {
      tableGridApi?.deselectAll()
    }
  }, [selectedCells, tableGridApi])

  const useCellBrowsingHotkeys = (key: string, action: CellBrowsingAction) =>
    useHotkeys(key, () => handleQueryUpdate(action), {}, [search, handleQueryUpdate])

  useCellBrowsingHotkeys('shift+z, z', { type: 'toggleCenterCrop', value: !query.centerCrop })
  useCellBrowsingHotkeys('shift+x, x', { type: 'toggleSharpen' })

  useEffect(() => {
    if (!selectedCells.length) {
      tableGridApi?.deselectAll()
    }
  }, [selectedCells, tableGridApi])

  useEffect(() => {
    if (search.sortOrderKey !== SAMPLE_DISTRIBUTION && !searchingAcrossCorpus) {
      setTimeout(async () => {
        return prefetchPages(queryClient, search, PAGES_TO_PREFETCH, displayOptions)
      }, PREFETCH_DELAY)
    }
  }, [search, queryClient, displayOptions, searchingAcrossCorpus])

  const cellDetailsOnGridReady = (params: GridReadyEvent) => {
    setCellDetailsGridApi(params.api)
  }

  const actionableBar = useMemo(() => {
    function onDownload() {
      cellDetailsGridApi?.exportDataAsCsv()
    }
    return (
      <Box sx={{ width: '32px', height: '32px' }} onClick={() => onDownload()}>
        <Tooltip title="Download cell details">
          <DownloadToggleIcon sx={IconSx} />
        </Tooltip>
      </Box>
    )
  }, [cellDetailsGridApi])

  useEffect(() => {
    if (selectedCells.length) {
      setSidebarOpen(false)
      setDetailsPanelOpen(true)
    } else {
      setDetailsPanelOpen(false)
    }
  }, [selectedCells.length])

  const customSplitterClass = (): string => {
    if (viewMode.table && !sidebarOpen) return ''
    if (viewMode.table && sidebarOpen) return 'shrink-width'
    if (viewMode.grid && search.cellId) return ''
    return 'responsive-width'
  }

  // Remove the ag-grid API reference when component unmounts
  useEffect(() => {
    return () => {
      setTableGridApi(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchSidebar
      open={sidebarOpen}
      setOpen={setSidebarOpen}
      mainContent={
        <CellSearchFilters
          setViewMode={setViewMode}
          disabledSubmit={searchingAcrossCorpus && !search.cellId}
          defaultSearchFilters={search}
          handleSearch={(searchFilters: SearchFilters) =>
            handleQueryUpdate({
              type: 'search',
              searchFilters,
            })
          }
          handleReset={() =>
            handleQueryUpdate({
              type: 'reset',
            })
          }
          onError={(errors) => console.error(errors)}
        />
      }
    >
      {searchingAcrossCorpus && !search.cellId ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>
          Please narrow your search down by including a search criteria
        </Typography>
      ) : (
        <>
          {/* Top Action Panel */}
          <CellSearchOptions
            searchOptions={search}
            displayOptions={displayOptions}
            handleSearchOptionAction={handleQueryUpdate}
            viewMode={viewMode}
            setViewMode={setViewMode}
            handleQueryUpdate={handleQueryUpdate}
          />

          <SplitterLayout
            customClassName={customSplitterClass()}
            percentage
            primaryIndex={0}
            secondaryMinSize={45}
          >
            <Box sx={{ height: '100%' }}>
              {selectedCells.length ? (
                <CellBrowserExport
                  handleQueryUpdate={handleQueryUpdate}
                  checkedCells={selectedCells}
                />
              ) : null}

              <ViewContainer
                exportPanel={!!selectedCells.length}
                sx={viewMode.table ? { padding: '0' } : { padding: '24px 30px 24px 24px' }}
              >
                {search.sortOrderKey === SAMPLE_DISTRIBUTION && search.runIds.length === 0 ? (
                  <Typography sx={{ textAlign: 'center', mt: 2 }}>
                    Please search with a Run Id
                  </Typography>
                ) : null}
                {viewMode.grid ? (
                  <CellBrowsingGridView handleQueryUpdate={handleQueryUpdate} />
                ) : (
                  <CellBrowsingTableView
                    tableGridApi={tableGridApi}
                    setTableGridApi={setTableGridApi}
                    selectedCells={selectedCells}
                  />
                )}
              </ViewContainer>
            </Box>
            {/* Cell Details Panel */}
            {detailsPanelOpen ? (
              <Box
                sx={{
                  height: 'calc(100% + 48px)',
                  overflowY: 'auto',
                  padding: '22px 24px 24px 24px',
                }}
              >
                <SectionCard
                  actionableBar={actionableBar}
                  title="Cell Details"
                  closeAction={() => setDetailsPanelOpen(false)}
                  paddingCSS="0"
                >
                  <CellDetailsPanel onGridReady={cellDetailsOnGridReady} searchOptions={search} />
                </SectionCard>
              </Box>
            ) : null}
          </SplitterLayout>
        </>
      )}
    </SearchSidebar>
  )
}

export default CellBrowsingPage
