import { Permission } from 'constants/permissions'
import { IdToken, LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { updateBearerToken } from 'utils/api'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import { getAnalytics,setUserId } from 'firebase/analytics'

export const USER_AUTHORIZATION_KEY = 'https://deepcell.com/user_authorization'
const ORG_ID_KEY = 'orgId'

export interface AuthTokensInterface {
  hasTokens: boolean
  permissions: Set<Permission>
  isInternalUser: boolean
  logout: (options: LogoutOptions) => void
  resetStorage: () => void
}

export const useAuthTokens = (): AuthTokensInterface => {
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, logout } =
    useAuthWrapper(useAuth0)
  const [hasTokens, setHasToken] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<Set<Permission>>(new Set())

  if (user && user.org_id) {
    localStorage.setItem(ORG_ID_KEY, user.org_id)
    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('currentUser', JSON.stringify(user))
    }
  }

  function resetStorage() {
    localStorage.removeItem(ORG_ID_KEY)
  }

  function logoutAndClearOrg(options: LogoutOptions) {
    resetStorage()
    logout(options)
  }

  /* Since we're keeping client permissions in the ID Token, the startup flow is slightly more complicated */
  useEffect(() => {
    async function updateToken(ignoreCache: boolean) {
      const token = await getAccessTokenSilently({ ignoreCache })
      updateBearerToken(token)
    }

    function isMissingPermissions(claims: IdToken) {
      return (
        !claims[USER_AUTHORIZATION_KEY] ||
        !claims[USER_AUTHORIZATION_KEY].permissions ||
        claims[USER_AUTHORIZATION_KEY].permissions.length === 0
      )
    }

    async function getClaims(): Promise<IdToken | undefined> {
      const result = await getIdTokenClaims()
      if (result && result?.[USER_AUTHORIZATION_KEY]?.permissions) {
        setPermissions(new Set(result[USER_AUTHORIZATION_KEY].permissions as Array<Permission>))
      }
      return result
    }

    async function updateTokens() {
      await updateToken(false)
      const claims = await getClaims()

      // Check if we have a role but no permissions - if so this may be the first signup where we just assigned
      // a default role.  Silently do another token refresh
      // https://community.auth0.com/t/how-do-i-add-a-default-role-permissions-to-a-user/43269/5
      //
      // Note that from reviewing the Auth0 code, refreshing the access token silently also updates the id token
      // So we get should get updated permissions in both the ID Token and Access Token after this
      if (!claims || isMissingPermissions(claims)) {
        await updateToken(true)
        await getClaims()

        // @TODO Handle the case where the user is still missing permissions for some reason with some
        // UI feedback. This should usually not happen, but there may be a mistake in the Auth0 config
      }
      if (claims && !isMissingPermissions(claims)) {
        setHasToken(true)
      }
    }

    if (isAuthenticated) {
      const analytics = getAnalytics()
      if (user && user.email) {
        setUserId(analytics,user.email)
      }
      updateTokens()
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user])

  const isInternalUser = permissions && permissions.has('deepcell_internal:read')

  return {
    hasTokens,
    permissions,
    isInternalUser,
    logout: logoutAndClearOrg,
    resetStorage,
  } as const
}

export default useAuthTokens
